<template>
  <div class="Jlyxoj" id="Jlyxoj">
    <div class="Jlyxoj-container flex">
      <div class="Jlyxoj-container-l">
        <div class="Jlyxoj-container-l-tit">运行评价</div>
        <div class="l-tab">
          <div class="l-tab-th flex">
            <div class="th-cell th-cell1">评价类型</div>
            <div class="th-cell"></div>
            <div class="th-cell">班运行</div>
            <div class="th-cell">日运行</div>
            <div class="th-cell">月运行</div>
            <div class="th-cell th-cell2">考核运行</div>
          </div>
            <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">自控率</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANZKL17', 'DB1V', 'ANZKL17_JLT','班总自控率')"
							@dblclick="Cclick(infoList.DB1V.ANZKL17, 'ANZKL17', 'DB1V', 'ANZKL17_JLT')"
            >
              {{infoList.DB1V.ANZKL17}}
              </div>
            <div class="th-cell"
              @click="toCompon(2, 'ANZKL18', 'DB1V', 'ANZKL18_JLT','日总自控率')"
							@dblclick="Cclick(infoList.DB1V.ANZKL18, 'ANZKL18', 'DB1V', 'ANZKL18_JLT')"
            >
              {{infoList.DB1V.ANZKL18}}
            </div>
            <div class="th-cell"
            @click="toCompon(2, 'ANZKL19', 'DB1V', 'ANZKL19_JLT','月总自控率')"
							@dblclick="Cclick(infoList.DB1V.ANZKL19, 'ANZKL19', 'DB1V', 'ANZKL19_JLT')"
            >
               {{infoList.DB1V.ANZKL19}}
            </div>
            <div class="th-cell th-cell2"
            @click="toCompon(2, 'ANZKL20', 'DB1V', 'ANZKL20_JLT','考核总自控率')"
							@dblclick="Cclick(infoList.DB1V.ANZKL20, 'ANZKL20', 'DB1V', 'ANZKL20_JLT')"
            >
               {{infoList.DB1V.ANZKL20}}
            </div>
          </div>
             <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">低塔进料流量均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV01', 'DB1V', 'ANEV01_JLT','低塔进料流量班均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV01, 'ANEV01', 'DB1V', 'ANEV01_JLT')"
            >
              {{infoList.DB1V.ANEV01}}
            </div>
            <div class="th-cell"
            @click="toCompon(2, 'ANEV02', 'DB1V', 'ANEV02_JLT','低塔进料流量日均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV02, 'ANEV02', 'DB1V', 'ANEV02_JLT')"
            >
               {{infoList.DB1V.ANEV02}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV03', 'DB1V', 'ANEV03_JLT','低塔进料流量月均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV03, 'ANEV03', 'DB1V', 'ANEV03_JLT')"
            >
               {{infoList.DB1V.ANEV03}}
            </div>
            <div class="th-cell th-cell2"
             @click="toCompon(2, 'ANEV04', 'DB1V', 'ANEV04_JLT','低塔进料流量考核均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV04, 'ANEV04', 'DB1V', 'ANEV04_JLT')"
            >
               {{infoList.DB1V.ANEV04}}
            </div>
          </div>
            <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">低塔回流流量均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV05', 'DB1V', 'ANEV05_JLT','低塔回流流量班均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV05, 'ANEV05', 'DB1V', 'ANEV05_JLT')"
            >
              {{infoList.DB1V.ANEV05}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV06', 'DB1V', 'ANEV06_JLT','低塔回流流量日均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV06, 'ANEV06', 'DB1V', 'ANEV06_JLT')"
            >
              {{infoList.DB1V.ANEV06}}
            </div>
            <div class="th-cell"
            @click="toCompon(2, 'ANEV07', 'DB1V', 'ANEV07_JLT','低塔回流流量月均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV07, 'ANEV07', 'DB1V', 'ANEV07_JLT')"
            >
              {{infoList.DB1V.ANEV07}}
            </div>
            <div class="th-cell th-cell2"
            @click="toCompon(2, 'ANEV08', 'DB1V', 'ANEV08_JLT','低塔回流流量考核均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV08, 'ANEV08', 'DB1V', 'ANEV08_JLT')"
            >
              {{infoList.DB1V.ANEV08}}
            </div>
          </div>
           <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">低塔热水阀位均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
            @click="toCompon(2, 'ANEV09', 'DB1V', 'ANEV09_JLT','低塔热水阀位班均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV09, 'ANEV09', 'DB1V', 'ANEV09_JLT')"
            >
               {{infoList.DB1V.ANEV09}}
            </div>
            <div class="th-cell"
              @click="toCompon(2, 'ANEV10', 'DB1V', 'ANEV10_JLT','低塔热水阀位日均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV10, 'ANEV10', 'DB1V', 'ANEV10_JLT')"
            >
              {{infoList.DB1V.ANEV10}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV11', 'DB1V', 'ANEV11_JLT','低塔热水阀位月均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV11, 'ANEV11', 'DB1V', 'ANEV11_JLT')"
            >
              {{infoList.DB1V.ANEV11}}
            </div>
            <div class="th-cell th-cell2"
            @click="toCompon(2, 'ANEV12', 'DB1V', 'ANEV12_JLT','低塔热水阀位考核均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV12, 'ANEV12', 'DB1V', 'ANEV12_JLT')"
            >
              {{infoList.DB1V.ANEV12}}
            </div>
          </div>
           <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">低塔冷凝阀位均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV13', 'DB1V', 'ANEV13_JLT','低塔冷凝阀位班均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV13, 'ANEV13', 'DB1V', 'ANEV13_JLT')"
            >
               {{infoList.DB1V.ANEV13}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV14', 'DB1V', 'ANEV14_JLT','低塔冷凝阀位日均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV14, 'ANEV14', 'DB1V', 'ANEV14_JLT')"
            >
               {{infoList.DB1V.ANEV14}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV15', 'DB1V', 'ANEV15_JLT','低塔冷凝阀位月均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV15, 'ANEV15', 'DB1V', 'ANEV15_JLT')"
            >
               {{infoList.DB1V.ANEV15}}
            </div>
            <div class="th-cell th-cell2"
               @click="toCompon(2, 'ANEV16', 'DB1V', 'ANEV16_JLT','低塔冷凝阀位考核均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV16, 'ANEV16', 'DB1V', 'ANEV16_JLT')"
            >
               {{infoList.DB1V.ANEV16}}
            </div>
          </div>
           <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">低塔塔顶压力均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV17', 'DB1V', 'ANEV17_JLT','低塔塔顶压力班均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV17, 'ANEV17', 'DB1V', 'ANEV17_JLT')"
            >
               {{infoList.DB1V.ANEV17}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV18', 'DB1V', 'ANEV18_JLT','低塔塔顶压力日均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV18, 'ANEV18', 'DB1V', 'ANEV18_JLT')"
            >
               {{infoList.DB1V.ANEV18}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV19', 'DB1V', 'ANEV19_JLT','低塔塔顶压力月均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV19, 'ANEV19', 'DB1V', 'ANEV19_JLT')"
            >
               {{infoList.DB1V.ANEV19}}
            </div>
            <div class="th-cell th-cell2"
             @click="toCompon(2, 'ANEV20', 'DB1V', 'ANEV20_JLT','低塔塔顶压力考核均值')"
						@dblclick="Cclick(infoList.DB1V.ANEV20, 'ANEV20', 'DB1V', 'ANEV20_JLT')"
            >
               {{infoList.DB1V.ANEV20}}
            </div>
          </div>
             <div class="l-tab-td1 flex">
            <div class="th-cell1">低塔塔釜温度</div>
            <div class="l-tab-td1-2 flex">
              <div class="l-tab-td1-2-cell">
                <div class="cell cell1">均值</div>
                 <div class="cell cell1">精度1占比</div>
                  <div class="cell cell1">精度2占比</div>
              </div>
               <div class="l-tab-td1-2-cell">
                <div class="cell"
                 @click="toCompon(2, 'ANEV21', 'DB1V', 'ANEV21_JLT','低塔塔釜温度班均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV21, 'ANEV21', 'DB1V', 'ANEV21_JLT')"
                >
                   {{infoList.DB1V.ANEV21}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV63', 'DB1V', 'ANEV63_JLT','低塔塔釜温度参数精度班占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV63, 'ANEV63', 'DB1V', 'ANEV63_JLT')"
                 >
                  {{infoList.DB1V.ANEV63}}
                 </div>
                  <div class="cell"
                     @click="toCompon(2, 'ANEV67', 'DB1V', 'ANEV67_JLT','低塔塔釜温度参数精度班占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV67, 'ANEV67', 'DB1V', 'ANEV67_JLT')"
                  >
                    {{infoList.DB1V.ANEV67}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                 @click="toCompon(2, 'ANEV22', 'DB1V', 'ANEV22_JLT','低塔塔釜温度日均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV22, 'ANEV22', 'DB1V', 'ANEV22_JLT')"
                >
                  {{infoList.DB1V.ANEV22}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV64', 'DB1V', 'ANEV64_JLT','低塔塔釜温度参数精度日占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV64, 'ANEV64', 'DB1V', 'ANEV64_JLT')"
                 >
                   {{infoList.DB1V.ANEV64}}
                 </div>
                  <div class="cell"
                   @click="toCompon(2, 'ANEV68', 'DB1V', 'ANEV68_JLT','低塔塔釜温度参数精度日占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV68, 'ANEV68', 'DB1V', 'ANEV68_JLT')"
                  >
                    {{infoList.DB1V.ANEV68}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                  @click="toCompon(2, 'ANEV23', 'DB1V', 'ANEV23_JLT','低塔塔釜温度月均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV23, 'ANEV23', 'DB1V', 'ANEV23_JLT')"
                >
                  {{infoList.DB1V.ANEV23}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV65', 'DB1V', 'ANEV65_JLT','低塔塔釜温度参数精度月占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV65, 'ANEV65', 'DB1V', 'ANEV65_JLT')"
                 >
                   {{infoList.DB1V.ANEV65}}
                 </div>
                  <div class="cell"
                  @click="toCompon(2, 'ANEV69', 'DB1V', 'ANEV69_JLT','低塔塔釜温度参数精度月占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV69, 'ANEV69', 'DB1V', 'ANEV69_JLT')"
                  >
                     {{infoList.DB1V.ANEV69}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell cell2"
                 @click="toCompon(2, 'ANEV24', 'DB1V', 'ANEV24_JLT','低塔塔釜温度考核均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV24, 'ANEV24', 'DB1V', 'ANEV24_JLT')"
                >
                  {{infoList.DB1V.ANEV24}}
                </div>
                 <div class="cell cell2"
                   @click="toCompon(2, 'ANEV66', 'DB1V', 'ANEV66_JLT','低塔塔釜温度参数精度考核占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV66, 'ANEV66', 'DB1V', 'ANEV66_JLT')"
                 >
                   {{infoList.DB1V.ANEV66}}
                 </div>
                  <div class="cell cell2"
                   @click="toCompon(2, 'ANEV70', 'DB1V', 'ANEV70_JLT','低塔塔釜温度参数精度考核占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV70, 'ANEV70', 'DB1V', 'ANEV70_JLT')"
                  >
                    {{infoList.DB1V.ANEV70}}
                  </div>
              </div>
            </div>
          </div>
                <div class="l-tab-td1 flex">
            <div class="th-cell1">低塔塔顶冷凝温度</div>
            <div class="l-tab-td1-2 flex">
              <div class="l-tab-td1-2-cell">
                <div class="cell cell1">均值</div>
                 <div class="cell cell1">精度1占比</div>
                  <div class="cell cell1">精度2占比</div>
              </div>
               <div class="l-tab-td1-2-cell">
                <div class="cell"
                @click="toCompon(2, 'ANEV25', 'DB1V', 'ANEV25_JLT','低塔塔釜温度班均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV25, 'ANEV25', 'DB1V', 'ANEV25_JLT')"
                >
                  {{infoList.DB1V.ANEV25}}
                </div>
                 <div class="cell"
                   @click="toCompon(2, 'ANEV71', 'DB1V', 'ANEV71_JLT','低塔塔釜温度参数精度班占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV71, 'ANEV71', 'DB1V', 'ANEV71_JLT')"
                 >
                  {{infoList.DB1V.ANEV71}}
                 </div>
                  <div class="cell"
                    @click="toCompon(2, 'ANEV75', 'DB1V', 'ANEV75_JLT','低塔塔釜温度参数精度班占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV75, 'ANEV75', 'DB1V', 'ANEV75_JLT')"
                  >
                    {{infoList.DB1V.ANEV75}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                  @click="toCompon(2, 'ANEV26', 'DB1V', 'ANEV26_JLT','低塔塔釜温度日均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV26, 'ANEV26', 'DB1V', 'ANEV26_JLT')"
                >
                   {{infoList.DB1V.ANEV26}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV72', 'DB1V', 'ANEV72_JLT','低塔塔釜温度参数精度日占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV72, 'ANEV72', 'DB1V', 'ANEV72_JLT')"
                 >
                  {{infoList.DB1V.ANEV72}}
                 </div>
                  <div class="cell"
                    @click="toCompon(2, 'ANEV76', 'DB1V', 'ANEV76_JLT','低塔塔釜温度参数精度日占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV76, 'ANEV76', 'DB1V', 'ANEV76_JLT')"
                  >
                    {{infoList.DB1V.ANEV76}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                 @click="toCompon(2, 'ANEV27', 'DB1V', 'ANEV27_JLT','低塔塔釜温度月均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV26, 'ANEV27', 'DB1V', 'ANEV27_JLT')"
                >
                   {{infoList.DB1V.ANEV27}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV73', 'DB1V', 'ANEV73_JLT','低塔塔釜温度参数精度月占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV73, 'ANEV73', 'DB1V', 'ANEV73_JLT')"
                 >
                  {{infoList.DB1V.ANEV73}}
                 </div>
                  <div class="cell"
                     @click="toCompon(2, 'ANEV77', 'DB1V', 'ANEV77_JLT','低塔塔釜温度参数精度月占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV77, 'ANEV77', 'DB1V', 'ANEV77_JLT')"
                  >
                    {{infoList.DB1V.ANEV77}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell cell2"
                 @click="toCompon(2, 'ANEV28', 'DB1V', 'ANEV28_JLT','低塔塔釜温度考核均值')"
						     @dblclick="Cclick(infoList.DB1V.ANEV28, 'ANEV28', 'DB1V', 'ANEV28_JLT')"
                >
                   {{infoList.DB1V.ANEV28}}
                </div>
                 <div class="cell cell2"
                   @click="toCompon(2, 'ANEV74', 'DB1V', 'ANEV74_JLT','低塔塔釜温度参数精度考核占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV74, 'ANEV74', 'DB1V', 'ANEV74_JLT')"
                 >
                  {{infoList.DB1V.ANEV74}}
                 </div>
                  <div class="cell cell2"
                   @click="toCompon(2, 'ANEV78', 'DB1V', 'ANEV78_JLT','低塔塔釜温度参数精度考核占比')"
						     @dblclick="Cclick(infoList.DB1V.ANEV78, 'ANEV78', 'DB1V', 'ANEV78_JLT')"
                  >
                    {{infoList.DB1V.ANEV78}}
                  </div>
              </div>
            </div>
          </div>
              <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">高塔进料流量均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV29', 'DB1V', 'ANEV29_JLT','高塔进料流量班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV29, 'ANEV29', 'DB1V', 'ANEV29_JLT')"
            >
              {{infoList.DB1V.ANEV29}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV30', 'DB1V', 'ANEV30_JLT','高塔进料流量日均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV30, 'ANEV30', 'DB1V', 'ANEV30_JLT')"
            >
               {{infoList.DB1V.ANEV30}}
            </div>
            <div class="th-cell"
              @click="toCompon(2, 'ANEV31', 'DB1V', 'ANEV31_JLT','高塔进料流量月均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV31, 'ANEV31', 'DB1V', 'ANEV31_JLT')"
            >
               {{infoList.DB1V.ANEV31}}
            </div>
            <div class="th-cell th-cell2"
             @click="toCompon(2, 'ANEV32', 'DB1V', 'ANEV32_JLT','高塔进料流量考核均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV32, 'ANEV32', 'DB1V', 'ANEV32_JLT')"
            >
               {{infoList.DB1V.ANEV32}}
            </div>
          </div>
          <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">高塔回流流量均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV33', 'DB1V', 'ANEV33_JLT','高塔回流流量班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV33, 'ANEV33', 'DB1V', 'ANEV33_JLT')"
            >
               {{infoList.DB1V.ANEV33}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV34', 'DB1V', 'ANEV34_JLT','高塔回流流量日均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV34, 'ANEV34', 'DB1V', 'ANEV34_JLT')"
            >
               {{infoList.DB1V.ANEV34}}
            </div>
            <div class="th-cell"
            @click="toCompon(2, 'ANEV35', 'DB1V', 'ANEV35_JLT','高塔回流流量月均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV35, 'ANEV35', 'DB1V', 'ANEV35_JLT')"
            >
               {{infoList.DB1V.ANEV35}}
            </div>
            <div class="th-cell th-cell2"
            @click="toCompon(2, 'ANEV36', 'DB1V', 'ANEV36_JLT','高塔回流流量考核均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV36, 'ANEV36', 'DB1V', 'ANEV36_JLT')"
            >
               {{infoList.DB1V.ANEV36}}
            </div>
          </div>
           <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">高塔热水阀位均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
              @click="toCompon(2, 'ANEV37', 'DB1V', 'ANEV37_JLT','高塔热水阀位班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV37, 'ANEV37', 'DB1V', 'ANEV37_JLT')"
            >
              {{infoList.DB1V.ANEV37}}
            </div>
            <div class="th-cell"
              @click="toCompon(2, 'ANEV38', 'DB1V', 'ANEV38_JLT','高塔热水阀位日均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV38, 'ANEV38', 'DB1V', 'ANEV38_JLT')"
            >
               {{infoList.DB1V.ANEV38}}
            </div>
            <div class="th-cell"
            @click="toCompon(2, 'ANEV39', 'DB1V', 'ANEV39_JLT','高塔热水阀位月均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV39, 'ANEV39', 'DB1V', 'ANEV39_JLT')"
            >
               {{infoList.DB1V.ANEV39}}
            </div>
            <div class="th-cell th-cell2"
              @click="toCompon(2, 'ANEV40', 'DB1V', 'ANEV40_JLT','高塔热水阀位考核均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV40, 'ANEV40', 'DB1V', 'ANEV40_JLT')"
            >
               {{infoList.DB1V.ANEV40}}
            </div>
          </div>
           <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">高塔冷凝阀位均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV41', 'DB1V', 'ANEV41_JLT','高塔冷凝阀位班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV41, 'ANEV41', 'DB1V', 'ANEV41_JLT')"
            >
               {{infoList.DB1V.ANEV41}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV42', 'DB1V', 'ANEV42_JLT','高塔冷凝阀位日均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV42, 'ANEV42', 'DB1V', 'ANEV42_JLT')"
            >
               {{infoList.DB1V.ANEV42}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV43', 'DB1V', 'ANEV43_JLT','高塔冷凝阀位月均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV43, 'ANEV43', 'DB1V', 'ANEV43_JLT')"
            >
               {{infoList.DB1V.ANEV43}}
            </div>
            <div class="th-cell th-cell2"
             @click="toCompon(2, 'ANEV44', 'DB1V', 'ANEV44_JLT','高塔冷凝阀位考核均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV44, 'ANEV44', 'DB1V', 'ANEV44_JLT')"
            >
               {{infoList.DB1V.ANEV44}}
            </div>
          </div>
          <div class="l-tab-th l-tab-td flex">
            <div class="th-cell th-cell1">高塔塔顶压力均值</div>
            <div class="th-cell"></div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV45', 'DB1V', 'ANEV45_JLT','高塔塔顶压力班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV45, 'ANEV45', 'DB1V', 'ANEV45_JLT')"
            >
              {{infoList.DB1V.ANEV45}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV46', 'DB1V', 'ANEV46_JLT','高塔塔顶压力日均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV46, 'ANEV46', 'DB1V', 'ANEV46_JLT')"
            >
              {{infoList.DB1V.ANEV46}}
            </div>
            <div class="th-cell"
             @click="toCompon(2, 'ANEV47', 'DB1V', 'ANEV47_JLT','高塔塔顶压力月均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV47, 'ANEV47', 'DB1V', 'ANEV47_JLT')"
            >
              {{infoList.DB1V.ANEV47}}
            </div>
            <div class="th-cell th-cell2"
             @click="toCompon(2, 'ANEV48', 'DB1V', 'ANEV48_JLT','高塔塔顶压力考核均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV48, 'ANEV48', 'DB1V', 'ANEV48_JLT')"
            >
              {{infoList.DB1V.ANEV48}}
            </div>
          </div>
                  <div class="l-tab-td1 flex">
            <div class="th-cell1">高塔塔釜温度</div>
            <div class="l-tab-td1-2 flex">
              <div class="l-tab-td1-2-cell">
                <div class="cell cell1">均值</div>
                 <div class="cell cell1">精度1占比</div>
                  <div class="cell cell1">精度2占比</div>
              </div>
               <div class="l-tab-td1-2-cell">
                <div class="cell"
                @click="toCompon(2, 'ANEV49', 'DB1V', 'ANEV49_JLT','高塔塔釜温度班均值')"
						  @dblclick="Cclick(infoList.DB1V.ANEV49, 'ANEV49', 'DB1V', 'ANEV49_JLT')"
                >
                   {{infoList.DB1V.ANEV49}}
                </div>
                 <div class="cell"
                @click="toCompon(2, 'ANEV79', 'DB1V', 'ANEV79_JLT','高塔塔釜温度参数班均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV79, 'ANEV79', 'DB1V', 'ANEV79_JLT')"
                 >
                  {{infoList.DB1V.ANEV79}}
                 </div>
                  <div class="cell"
                  @click="toCompon(2, 'ANEV83', 'DB1V', 'ANEV83_JLT','高塔塔釜温度参数班均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV83, 'ANEV83', 'DB1V', 'ANEV83_JLT')"
                  >
                     {{infoList.DB1V.ANEV83}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                @click="toCompon(2, 'ANEV50', 'DB1V', 'ANEV50_JLT','高塔塔釜温度日均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV50, 'ANEV50', 'DB1V', 'ANEV50_JLT')"
                >
                  {{infoList.DB1V.ANEV50}}
                </div>
                 <div class="cell"
                @click="toCompon(2, 'ANEV80', 'DB1V', 'ANEV80_JLT','高塔塔釜温度参数日均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV80, 'ANEV80', 'DB1V', 'ANEV80_JLT')"
                 >
                  {{infoList.DB1V.ANEV80}}
                 </div>
                  <div class="cell" 
                   @click="toCompon(2, 'ANEV84', 'DB1V', 'ANEV84_JLT','高塔塔釜温度参数日均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV84, 'ANEV84', 'DB1V', 'ANEV84_JLT')"
                  >
                     {{infoList.DB1V.ANEV84}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                @click="toCompon(2, 'ANEV51', 'DB1V', 'ANEV51_JLT','高塔塔釜温度月均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV51, 'ANEV51', 'DB1V', 'ANEV51_JLT')"
                >
                  {{infoList.DB1V.ANEV51}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV81', 'DB1V', 'ANEV81_JLT','高塔塔釜温度参数月均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV81, 'ANEV81', 'DB1V', 'ANEV81_JLT')"
                 >
                   {{infoList.DB1V.ANEV81}}
                 </div>
                  <div class="cell"
                     @click="toCompon(2, 'ANEV85', 'DB1V', 'ANEV85_JLT','高塔塔釜温度参数月均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV85, 'ANEV85', 'DB1V', 'ANEV85_JLT')"
                  >
                     {{infoList.DB1V.ANEV85}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell cell2"
                @click="toCompon(2, 'ANEV52', 'DB1V', 'ANEV52_JLT','高塔塔釜温度考核均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV52, 'ANEV52', 'DB1V', 'ANEV52_JLT')"
                >
                  {{infoList.DB1V.ANEV52}}
                </div>
                 <div class="cell cell2"
                  @click="toCompon(2, 'ANEV82', 'DB1V', 'ANEV82_JLT','高塔塔釜温度参数考核均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV82, 'ANEV82', 'DB1V', 'ANEV82_JLT')"
                 >
                   {{infoList.DB1V.ANEV82}}
                 </div>
                  <div class="cell cell2"
                   @click="toCompon(2, 'ANEV86', 'DB1V', 'ANEV86_JLT','高塔塔釜温度参数考核均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV86, 'ANEV86', 'DB1V', 'ANEV86_JLT')"
                  >
                     {{infoList.DB1V.ANEV86}}
                  </div>
              </div>
            </div>
          </div>
        <div class="l-tab-td1 flex l-tab-td2">
            <div class="th-cell1">高塔塔顶温度</div>
            <div class="l-tab-td1-2 flex">
              <div class="l-tab-td1-2-cell">
                <div class="cell cell1">均值</div>
                 <div class="cell cell1">精度1占比</div>
                  <div class="cell cell1 cell3">精度2占比</div>
              </div>
               <div class="l-tab-td1-2-cell">
                <div class="cell"
                 @click="toCompon(2, 'ANEV53', 'DB1V', 'ANEV53_JLT','高塔塔顶温度班均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV53, 'ANEV53', 'DB1V', 'ANEV53_JLT')"
                >
                  {{infoList.DB1V.ANEV53}}
                </div>
                 <div class="cell"
                   @click="toCompon(2, 'ANEV87', 'DB1V', 'ANEV87_JLT','高塔塔顶温度参数精度班占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV87, 'ANEV87', 'DB1V', 'ANEV87_JLT')"
                 >
                   {{infoList.DB1V.ANEV87}}
                 </div>
                  <div class="cell cell3"
                     @click="toCompon(2, 'ANEV91', 'DB1V', 'ANEV91_JLT','高塔塔顶温度参数精度班占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV91, 'ANEV91', 'DB1V', 'ANEV91_JLT')"
                  >
                      {{infoList.DB1V.ANEV91}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell"
                 @click="toCompon(2, 'ANEV54', 'DB1V', 'ANEV54_JLT','高塔塔顶温度日均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV54, 'ANEV54', 'DB1V', 'ANEV54_JLT')"
                >
                  {{infoList.DB1V.ANEV54}}
                </div>
                 <div class="cell"
                  @click="toCompon(2, 'ANEV88', 'DB1V', 'ANEV88_JLT','高塔塔顶温度参数精度日占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV88, 'ANEV88', 'DB1V', 'ANEV88_JLT')"
                 >
                   {{infoList.DB1V.ANEV88}}
                 </div>
                  <div class="cell cell3"
                   @click="toCompon(2, 'ANEV92', 'DB1V', 'ANEV92_JLT','高塔塔顶温度参数精度日占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV92, 'ANEV92', 'DB1V', 'ANEV92_JLT')"
                  >
                     {{infoList.DB1V.ANEV92}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell "
                 @click="toCompon(2, 'ANEV55', 'DB1V', 'ANEV55_JLT','高塔塔顶温度月均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV55, 'ANEV55', 'DB1V', 'ANEV55_JLT')"
                >
                  {{infoList.DB1V.ANEV55}}
                </div>
                 <div class="cell"
                 @click="toCompon(2, 'ANEV89', 'DB1V', 'ANEV89_JLT','高塔塔顶温度参数精度月占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV89, 'ANEV89', 'DB1V', 'ANEV89_JLT')"
                 >
                   {{infoList.DB1V.ANEV89}}
                 </div>
                  <div class="cell cell3"
                  @click="toCompon(2, 'ANEV93', 'DB1V', 'ANEV93_JLT','高塔塔顶温度参数精度月占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV93, 'ANEV93', 'DB1V', 'ANEV93_JLT')"
                  >
                    {{infoList.DB1V.ANEV93}}
                  </div>
              </div>
              <div class="l-tab-td1-2-cell">
                <div class="cell cell2 "
                @click="toCompon(2, 'ANEV56', 'DB1V', 'ANEV56_JLT','高塔塔顶温度考核均值')"
						    @dblclick="Cclick(infoList.DB1V.ANEV56, 'ANEV56', 'DB1V', 'ANEV56_JLT')"
                >
                  {{infoList.DB1V.ANEV56}}
                </div>
                 <div class="cell cell2"
                   @click="toCompon(2, 'ANEV90', 'DB1V', 'ANEV90_JLT','高塔塔顶温度参数精度考核占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV90, 'ANEV90', 'DB1V', 'ANEV90_JLT')"
                 >
                   {{infoList.DB1V.ANEV90}}
                 </div>
                  <div class="cell cell2 cell3"
                       @click="toCompon(2, 'ANEV94', 'DB1V', 'ANEV94_JLT','高塔塔顶温度参数精度考核占比')"
						    @dblclick="Cclick(infoList.DB1V.ANEV90, 'ANEV94', 'DB1V', 'ANEV94_JLT')"
                  >
                    {{infoList.DB1V.ANEV94}}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Jlyxoj-container-c">
        <div class="Jlyxoj-container-c-tit">运行评价参数</div>
        <div class="Jlyxoj-container-c-tab">
          <div class="Jlyxoj-container-c-tab-t">
              <div class="c-tab-th flex">
            <div class="th-cell">参数设定</div>
            <div class="th-cell th-cell1">精度设定</div>
          </div>
               <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2">温度参数1精度</div>
            <div class="th-cell th-cell3 th-cell1"
              @click="toCompon(2, 'ANEV57', 'DB1V', 'ANEV57_JLT','温度参数精度1')"
						    @dblclick="Cclick(infoList.DB1V.ANEV57, 'ANEV57', 'DB1V', 'ANEV57_JLT')"
            >
               {{infoList.DB1V.ANEV57}}
            </div>
          </div>
            <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2">温度参数2精度</div>
            <div class="th-cell th-cell3 th-cell1"
            @click="toCompon(2, 'ANEV58', 'DB1V', 'ANEV58_JLT','温度参数精度2')"
						@dblclick="Cclick(infoList.DB1V.ANEV58, 'ANEV58', 'DB1V', 'ANEV58_JLT')"
            >
              {{infoList.DB1V.ANEV58}}
            </div>
          </div>
           <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2">压力参数3精度</div>
            <div class="th-cell th-cell3 th-cell1"
             @click="toCompon(2, 'ANEV59', 'DB1V', 'ANEV59_JLT','压力参数3精度')"
						@dblclick="Cclick(infoList.DB1V.ANEV59, 'ANEV59', 'DB1V', 'ANEV59_JLT')"
            >
              {{infoList.DB1V.ANEV59}}
            </div>
          </div>
           <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2">压力参数4精度</div>
            <div class="th-cell th-cell3 th-cell1"
             @click="toCompon(2, 'ANEV60', 'DB1V', 'ANEV60_JLT','压力参数4精度')"
						@dblclick="Cclick(infoList.DB1V.ANEV60, 'ANEV60', 'DB1V', 'ANEV60_JLT')"
            >
              {{infoList.DB1V.ANEV60}}
            </div>
          </div>
           <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2">压力参数5精度</div>
            <div class="th-cell th-cell3 th-cell1"
                @click="toCompon(2, 'ANEV61', 'DB1V', 'ANEV61_JLT','压力参数5精度')"
						@dblclick="Cclick(infoList.DB1V.ANEV61, 'ANEV61', 'DB1V', 'ANEV61_JLT')"
            >
               {{infoList.DB1V.ANEV61}}
            </div>
          </div>
           <div class="c-tab-th c-tab-td flex">
            <div class="th-cell th-cell2 th-cell4">压力参数6精度</div>
            <div class="th-cell th-cell3 th-cell4 th-cell1"
                @click="toCompon(2, 'ANEV62', 'DB1V', 'ANEV62_JLT','压力参数6精度')"
						@dblclick="Cclick(infoList.DB1V.ANEV62, 'ANEV62', 'DB1V', 'ANEV62_JLT')"
            >
               {{infoList.DB1V.ANEV62}}
            </div>
          </div>
          </div>
          <div class="Jlyxoj-container-c-tab-zwf"></div>
            <div class="Jlyxoj-container-c-tab-c">
              <div class="c-tab-c-th flex">
                <div class="th-cell">
                  设定时长：
                </div>
                <div class="th-cell th-cell1 flex align-cen">
                    <div class="th-cell-1"
                     @click="toCompon(2, 'ZDYKH_TSP', 'PUB', 'ZDYKH_TSP','设定时长')"
					        	@dblclick="Cclick(infoList.PUB.ZDYKH_TSP, 'ZDYKH_TSP', 'PUB', 'ZDYKH_TSP')"
                    >
                      {{infoList.PUB.ZDYKH_TSP}}
                    </div>
                    <div class="th-cell-2" :style="{background:infoList.PUB.ZDYKHKS ? '#2AFC30' : 'red'}">
                      {{infoList.PUB.ZDYKHKS ? '开始考核' : '停止考核'}}
                      </div>
                </div>
              </div>
               <div class="c-tab-c-th flex">
                <div class="th-cell">
                  己考核时长：
                </div>
                <div class="th-cell th-cell2 th-cell1"
                   @click="toCompon(2, 'ZDYKH_TRUN', 'PUB', 'ZDYKH_TRUN','己考核时长')"
					        	@dblclick="Cclick(infoList.PUB.ZDYKH_TRUN, 'ZDYKH_TRUN', 'PUB', 'ZDYKH_TRUN')"
                >
                   {{infoList.PUB.ZDYKH_TRUN}}
                </div>
              </div>
              <div class="c-tab-c-th flex">
                <div class="th-cell">
                  开始时间：
                </div>
                <div class="th-cell th-cell2 th-cell1">
                 -
                </div>
              </div>
               <div class="c-tab-c-th flex">
                <div class="th-cell">
                  结束时间：
                </div>
                <div class="th-cell th-cell2 th-cell1">
                -
                </div>
              </div>
                   <div class="c-tab-c-th c-tab-c-th1 flex">
                <div class="th-cell">
                  自定义考核状态：
                </div>
                <div class="th-cell th-cell1 flex align-cen">
                    <div class="th-cell-3" :style="{background:infoList.PUB.ZDYKHZT ? '#2AFC30' : 'red'}">
                        {{infoList.PUB.ZDYKHZT ? '正在考核' : '等待考核'}}
                    </div>
                </div>
              </div>
            </div>
             <div class="Jlyxoj-container-c-tab-zwf1"></div>
              <div class="Jlyxoj-container-c-tab-b">
                <div class="c-tab-b-th  flex">
                  <div class="th-cell th-cell1">
                       系统时钟设定
                  </div>
                </div>
                    <div class="c-tab-b-th flex">
                <div class="th-cell">
                  控制器时钟：
                </div>
                <div class="th-cell th-cell2">
          {{infoList.PUB.SYS_YEAR}}年{{infoList.PUB.SYS_MONTH}}月{{infoList.PUB.SYS_DAY}}日  {{Math.floor(infoList.PUB.SYS_HOUR)}}:{{Math.floor(infoList.PUB.SYS_Minute)}}:{{Math.floor(infoList.PUB.SYS_SECOND)}}
                </div>
              </div>
                 <div class="c-tab-b-th flex">
                <div class="th-cell">
                  交班时间1设定：
                </div>
                <div class="th-cell th-cell2">
                 {{infoList.PUB.TIME_S1}}:{{infoList.PUB.TIME_M1}}
                </div>
              </div>
               <div class="c-tab-b-th flex">
                <div class="th-cell">
                  交班时间2设定：
                </div>
                <div class="th-cell th-cell2">
                 {{infoList.PUB.TIME_S2}}:{{infoList.PUB.TIME_M2}}
                </div>
              </div>
                  <div class="c-tab-b-th flex">
                <div class="th-cell th-cell3">
                  交班时间3设定：
                </div>
                <div class="th-cell th-cell2 th-cell3">
                 {{infoList.PUB.TIME_S3}}:{{infoList.PUB.TIME_M3}}
                </div>
              </div>
                <div class="c-tab-b-th flex">
                <div class="th-cell th-cell3">
                  日复位时间设定：
                </div>
                <div class="th-cell th-cell2 th-cell3">
               每日 {{infoList.PUB.TIME_S4}}:{{infoList.PUB.TIME_M4}}
                </div>
              </div>
                <div class="c-tab-b-th flex">
                <div class="th-cell th-cell3">
                  月复位时间设定：
                </div>
                <div class="th-cell th-cell2 th-cell3">
               每月 {{infoList.PUB.TIME_D}} 日
                </div>
              </div>
              <div class="c-tab-b-th1 flex align-cen">
                <div class="th1-img flex"><img src="../../../assets/images/jltzn1.png" alt=""></div>
                <div class="th1-cell">
                  <div class="cell-rate"
                  @click="toCompon(2, 'ANZKL16', 'DB1V', 'ANZKL16_JLT','瞬时自控率')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL16, 'ANZKL16', 'DB1V', 'ANZKL16_JLT')"
                  >
                    {{infoList.DB1V.ANZKL16}}%</div>
                 <div class="cell-te">瞬时总自控率</div>
                </div>
              </div>
              </div>
        </div>
      </div>
      <div class="Jlyxoj-container-r">
         <div class="Jlyxoj-container-c-tit">自控率统计</div>
         <div class="Jlyxoj-container-r-tab">
          <div class="Jlyxoj-container-r-tab-t">
            <div class="r-tab-t-th flex">
              <div class="th-cell th-cell1 th-cell2">
                  回路名称
                </div>
                <div class="th-cell th-cell1">
                  参与统计
                </div>
                <div class="th-cell th-cell1">
                  投运状态
                </div>
                <div class="th-cell th-cell1 th-cell3">
                  本班自控率
                </div>
            </div>
             <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  低沸塔进料阀
                </div>
                <div class="th-cell th-cell4" :style="infoList.DB1V.DGZKL01 | bgFillter">
                  {{infoList.DB1V.DGZKL01 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW01 | bgFillter">
                 {{infoList.DB1S.SW01 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL01', 'DB1V', 'ANZKL01_JLT','低沸塔进料阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL01, 'ANZKL01', 'DB1V', 'ANZKL01_JLT')"
                >
                 {{infoList.DB1V.ANZKL01}}
                </div>
            </div>
              <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  低沸塔冷凝阀
                </div>
                <div class="th-cell th-cell4" :style="infoList.DB1V.DGZKL02 | bgFillter">
                  {{infoList.DB1V.DGZKL02 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW02 | bgFillter">
                  {{infoList.DB1S.SW02 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL02', 'DB1V', 'ANZKL02_JLT','低沸塔冷凝阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL02, 'ANZKL02', 'DB1V', 'ANZKL02_JLT')"
                >
                 {{infoList.DB1V.ANZKL02}}
                </div>
            </div>
              <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  低沸塔热水阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL03 | bgFillter">
                  {{infoList.DB1V.DGZKL03 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW03 | bgFillter">
                  {{infoList.DB1S.SW03 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                  @click="toCompon(2, 'ANZKL03', 'DB1V', 'ANZKL03_JLT','低沸塔热水阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL03, 'ANZKL03', 'DB1V', 'ANZKL03_JLT')"
                >
                  {{infoList.DB1V.ANZKL03}}
                </div>
            </div>
             <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  低沸塔回流阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL04 | bgFillter">
                  {{infoList.DB1V.DGZKL04 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW04 | bgFillter">
                {{infoList.DB1S.SW04 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL04', 'DB1V', 'ANZKL04_JLT','低沸塔回流阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL04, 'ANZKL04', 'DB1V', 'ANZKL04_JLT')"
                >
                  {{infoList.DB1V.ANZKL04}}
                </div>
            </div>
            <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  高沸塔进料阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL05 | bgFillter">
                  {{infoList.DB1V.DGZKL05 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW05 | bgFillter">
                 {{infoList.DB1S.SW05 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                @click="toCompon(2, 'ANZKL05', 'DB1V', 'ANZKL05_JLT','高沸塔进料阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL05, 'ANZKL05', 'DB1V', 'ANZKL05_JLT')"
                >
                  {{infoList.DB1V.ANZKL05}}
                </div>
            </div>
            <div class="r-tab-t-th flex">
              <div class="th-cell th-cell2">
                  高沸塔冷凝阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL06 | bgFillter">
                   {{infoList.DB1V.DGZKL06 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW06 | bgFillter">
                {{infoList.DB1S.SW06 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                @click="toCompon(2, 'ANZKL06', 'DB1V', 'ANZKL06_JLT','高沸塔冷凝阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL06, 'ANZKL06', 'DB1V', 'ANZKL06_JLT')"
                >
                 {{infoList.DB1V.ANZKL06}}
                </div>
            </div>
                <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                  高沸塔回流阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL07 | bgFillter">
                  {{infoList.DB1V.DGZKL07 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW07 | bgFillter">
                 {{infoList.DB1S.SW07 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL07', 'DB1V', 'ANZKL07_JLT','高沸塔回流阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL07, 'ANZKL07', 'DB1V', 'ANZKL07_JLT')"
                >
                 {{infoList.DB1V.ANZKL07}}
                </div>
            </div>
                <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                 高沸塔热水阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL08 | bgFillter">
                  {{infoList.DB1V.DGZKL08 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW08 | bgFillter">
                 {{infoList.DB1S.SW08 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4" 
                 @click="toCompon(2, 'ANZKL08', 'DB1V', 'ANZKL08_JLT','高沸塔热水阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL08, 'ANZKL08', 'DB1V', 'ANZKL08_JLT')"
                >
                 {{infoList.DB1V.ANZKL08}}
                </div>
            </div>
                <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                 高沸塔出料阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL09 | bgFillter">
                  {{infoList.DB1V.DGZKL09 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW09 | bgFillter">
                 {{infoList.DB1S.SW09 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL09', 'DB1V', 'ANZKL09_JLT','高沸塔出料阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL09, 'ANZKL09', 'DB1V', 'ANZKL09_JLT')"
                >
                 {{infoList.DB1V.ANZKL09}}
                </div>
            </div>
             <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                 5℃水阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL10 | bgFillter">
                   {{infoList.DB1V.DGZKL10 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW10 | bgFillter">
                  {{infoList.DB1S.SW10 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL10', 'DB1V', 'ANZKL10_JLT','5℃水调节阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL10, 'ANZKL10', 'DB1V', 'ANZKL10_JLT')"
                >
                  {{infoList.DB1V.ANZKL10}}
                </div>
            </div>
              <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                 尾排阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL11 | bgFillter">
                  {{infoList.DB1V.DGZKL11 | textFillter}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW11 | bgFillter">
                 {{infoList.DB1S.SW11 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                  @click="toCompon(2, 'ANZKL11', 'DB1V', 'ANZKL11_JLT','尾排阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL11, 'ANZKL11', 'DB1V', 'ANZKL11_JLT')"
                >
                 {{infoList.DB1V.ANZKL11}}
                </div>
            </div>
              <div class="r-tab-t-th  r-tab-t-th1 flex">
              <div class="th-cell th-cell2">
                 全凝器5℃水阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL12 | bgFillter">
                  {{infoList.DB1V.DGZKL12}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW12 | bgFillter">
                 {{infoList.DB1S.SW12 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                @click="toCompon(2, 'ANZKL12', 'DB1V', 'ANZKL12_JLT','全凝器5℃水调节阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL12, 'ANZKL12', 'DB1V', 'ANZKL12_JLT')"
                >
                 {{infoList.DB1V.ANZKL12}}
                </div>
            </div>
            <div class="r-tab-t-th  flex">
              <div class="th-cell th-cell2">
                 尾冷A阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL13 | bgFillter">
                  {{infoList.DB1V.DGZKL13}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW13 | bgFillter">
                 {{infoList.DB1S.SW13 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL13', 'DB1V', 'ANZKL13_JLT',' 尾冷A调节阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL13, 'ANZKL13', 'DB1V', 'ANZKL13_JLT')"
                >
                  {{infoList.DB1V.ANZKL13}}
                </div>
            </div>
              <div class="r-tab-t-th  flex">
              <div class="th-cell th-cell2">
                 尾冷B阀
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1V.DGZKL14 | bgFillter">
                   {{infoList.DB1V.DGZKL14}}
                </div>
                <div class="th-cell th-cell5" :style="infoList.DB1S.SW14 | bgFillter">
                 {{infoList.DB1S.SW14 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4"
                 @click="toCompon(2, 'ANZKL14', 'DB1V', 'ANZKL14_JLT',' 尾冷B调节阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL14, 'ANZKL14', 'DB1V', 'ANZKL14_JLT')"
                >
                  {{infoList.DB1V.ANZKL14}}
                </div>
            </div>
              <div class="r-tab-t-th  flex">
              <div class="th-cell th-cell2 th-cell6">
                 尾冷C阀
                </div>
                <div class="th-cell th-cell5 th-cell6" :style="infoList.DB1V.DGZKL15 | bgFillter">
                   {{infoList.DB1V.DGZKL15}}
                </div>
                <div class="th-cell th-cell5 th-cell6" :style="infoList.DB1S.SW15 | bgFillter">
                 {{infoList.DB1S.SW15 | text1Fillter}}
                </div>
                <div class="th-cell th-cell3 th-cell4 th-cell6"
                    @click="toCompon(2, 'ANZKL15', 'DB1V', 'ANZKL15_JLT',' 尾冷C调节阀自控率使能开关')"
							  @dblclick="Cclick(infoList.DB1V.ANZKL15, 'ANZKL15', 'DB1V', 'ANZKL15_JLT')"
                >
                   {{infoList.DB1V.ANZKL15}}
                </div>
            </div>
          </div>
              <div class="Jlyxoj-container-c-tab-zwf1"></div>
              <div class="Jlyxoj-container-r-tab-b flex align-cen">
                <div class="r-tab-b-th1">综合评价</div>
                <div class="r-tab-b-th2 flex align-cen">
                  <div class="th2-cell1 flex">
                    <span>D</span>
                    <span>D</span>
                    <span>D</span>
                    </div> 
                  <div class="th2-cell2">
                    <span>100.00</span>
                    </div> 
                </div>
              </div>
         </div>
      </div>
    </div>
     <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
  </div>
</template>
<script>
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name:'Jlyxoj',
  	components: {
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {}; // 默认值
				},
			},
		},
  data () {
    return {
      chName: '',
				isComShow: false,
				isHshow: false,
				isClose: false,
				historyname: "",
				node: "",
				Lkname: "",
				spotArr: [],
				grouptime: null,
    }
  },
  watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
    created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) : [];
		},
    methods: {
      	Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
      isClose1() {
				this.isHshow = false;
			},
      	toCompon(key, name, name2, name3, name4) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (this.isMshow = true);
						case 1:
							return (this.isFshow = true);
						case 2:
							return (this.isHshow = true, this.chName = name4);
					}
				}, 300);
			},
    },
    filters: {
      // 文字颜色过滤器
      bgFillter(val){
        if (val ) {
					return 'color:#00e4ff';
				} else{
         return 'color:#fff';
        }
      },
      // 文字过滤器
      textFillter: (val) => {
				if (val) {
					return '参与';
				} else{
        return '不参与';
       }
			},
      text1Fillter: (val) => {
				if (val) {
					return '自动';
				} else{
        return '手动';
       }
			},
    }
}
</script>
<style lang="scss" scoped>
#Jlyxoj{
  width: 96vw;
  margin: 0vh auto;
  height: 79vh;
  position: relative;
  margin-top: 3vh;
  .Jlyxoj-container{
    height: inherit;
    .Jlyxoj-container-l{
      width: 39vw;
      height: 100%;
      .Jlyxoj-container-l-tit{
        font-size: 1vw;
        height: 2vh;
        line-height: 2vh;
        color: #fff;
        text-align: center;
        margin-bottom: 1vh;
      }
      .l-tab{
        height: 76vh;
        width: 100%;
        border: 1px solid rgba(0,228,255,.2);
        .l-tab-th{
          height: 3.3vh;
          line-height: 3.3vh;
           .th-cell{
            width: 6vw;
            color: #458ca4;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              cursor: pointer;
           }
           .th-cell1{width: 9vw;}
           .th-cell2{border-right: 0;}
        }
        .l-tab-td{
          .th-cell{color: #fff;}
           .th-cell1{color: #8aeaff;}
        }
        .l-tab-td1{
          height: 9.1vh;
         .th-cell1{
          width: 9vw;
          color: #8aeaff;
          line-height: 9.1vh;
           border-right: 1px solid rgba(0,228,255,.2);
           border-bottom: 1px solid rgba(0,228,255,.2);
           font-size: .8vw;
           text-align: center;
           box-sizing: border-box;
         }
         .l-tab-td1-2{
          width: 30vw;
          height: 9.1vh;
          .l-tab-td1-2-cell{
            width: 6vw;
            .cell{
              height: 3.03vh;
            line-height: 3.03vh;
            color: #fff;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              box-sizing: border-box;
              cursor: pointer;
            }
            .cell1{color:#8aeaff}
            .cell2{border-right: 0;}
          }
         }
        }
        .l-tab-td2{
          .th-cell1{
            border-bottom: none;
          }
          .cell3{border-bottom: none !important;}
        }
      }
    }
    .Jlyxoj-container-c{
      margin: 0 2vw;
      width: 22vw;
      height: 100%;
      .Jlyxoj-container-c-tit{
      font-size: 1vw;
      height: 2vh;
      line-height: 2vh;
      color: #fff;
      text-align: center;
      margin-bottom: 1vh;
      }
      .Jlyxoj-container-c-tab{
         height: 76vh;
        width: 100%;
        .Jlyxoj-container-c-tab-t{
          width: 100%;
          height: 23.1vh;
           border: 1px solid rgba(0,228,255,.2);
           .c-tab-th{
             height: 3.3vh;
            line-height: 3.3vh;
            .th-cell{
            width: 50%;
            color: #458ca4;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              cursor: pointer;
           }
            .th-cell1{border-right: none;}
            .th-cell2{color: #8aeaff;}
            .th-cell3{color: #00e4ff;}
            .th-cell4{border-bottom: 0;}
           }
        }
        .Jlyxoj-container-c-tab-zwf{
           height: 3.03vh;
        }
          .Jlyxoj-container-c-tab-zwf1{
           height: 3.3vh;
        }
        .Jlyxoj-container-c-tab-c{
          width: 100%;
          height: 15.17vh;
          border: 1px solid rgba(0,228,255,.2);
          box-sizing: border-box;
          .c-tab-c-th{
            width: 100%;
           .th-cell{
            height: 3.03vh;
            line-height: 3.03vh;
              color: #8aeaff;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              width: 50%;
              box-sizing: border-box;
           }
           .th-cell1{
            border-right: 0;
            padding: 0 .5vw;
            .th-cell-1{
              color: #00e4ff;
            }
            .th-cell-2{
              width: 5vw;
              height: 2.5vh;
              line-height: 2.5vh;
              background-color: #bb1d36;
              text-align: center;
              margin-left: auto;
              color: #fff;
            }
             .th-cell-3{
              width: 5vw;
              height: 2.5vh;
              line-height: 2.5vh;
              background-color: #2AFC30;
              text-align: center;
              margin: 0 auto;
              
              color: #fff;
            }
            }
            .th-cell2{color: #fff;}
          }
          .c-tab-c-th1{
            .th-cell{border-bottom: none;}
          }
        }
        .Jlyxoj-container-c-tab-b{
          width: 100%;
          height: 31.4vh;
           border: 1px solid rgba(0,228,255,.2);
          box-sizing: border-box;
          .c-tab-b-th{
             width: 100%;
             .th-cell{
              height: 3.3vh;
              line-height: 3.3vh;
                 color: #8aeaff;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              width: 50%;
              box-sizing: border-box;
             }
             .th-cell1{width: 100%;text-align: center;border-right: none;}
              .th-cell2{color: #fff;border-right: none;}
              .th-cell3{ height: 3.03vh; line-height: 3.03vh;}
          }
          .c-tab-b-th1{
            width: 100%;
            height: 9.1vh;
            .th1-img{
              width: 50%;
              justify-content: end;
              img{
                width: 4vw;
                height: 7vh;
              }
            }
            .th1-cell{
              width: 50%;
              text-align: center;
              .cell-rate{
                color: #00e4ff;
                font-size: 2vw;
              }
              .cell-te{
                color: #8aeaff;
                font-size: .8vw;
              }
            }
          }
        }
      }
    }
    .Jlyxoj-container-r{
      width: 29vw;
      height: 100%;
     .Jlyxoj-container-c-tit{
      font-size: 1vw;
      height: 2vh;
      line-height: 2vh;
      color: #fff;
      text-align: center;
      margin-bottom: 1vh;
      }
          .Jlyxoj-container-r-tab{
         height: 76vh;
        width: 100%;
                  .Jlyxoj-container-c-tab-zwf1{
           height: 3.3vh;
        }
        .Jlyxoj-container-r-tab-t{
          width: 100%;
          height: 51.2vh;
          border: 1px solid rgba(0,228,255,.2);
          .r-tab-t-th{
            width: 100%;
              .th-cell{
            height: 3.3vh;
            line-height: 3.3vh;
              color: #8aeaff;
            font-size: .8vw;
            text-align: center;
             border-right: 1px solid rgba(0,228,255,.2);
              border-bottom: 1px solid rgba(0,228,255,.2);
              width: 7vw;
              box-sizing: border-box;
           }
             .th-cell1{color: #458ca4;}
             .th-cell2{width: 8vw;}
             .th-cell3{border-right: none;}
             .th-cell4{color: #fff;}
             .th-cell5{color: #00e4ff;}
             .th-cell6{border-bottom: none;}
          }
          .r-tab-t-th1{
            .th-cell{
               height: 3.03vh;
            line-height: 3.03vh;
            }
          }
        }
        .Jlyxoj-container-r-tab-b{
          width: 100%;
          height: 21.5vh;
          border: 1px solid rgba(0,228,255,.2);
          box-sizing: border-box;
          .r-tab-b-th1{
            width: 8vw;
            border-right: 1px solid rgba(0,228,255,.2);
            height: 100%;
            color: #fff;
            text-align: center;
            line-height: 21.5vh;
          }
          .r-tab-b-th2{
            justify-content: space-around;
            width: 100%;
            .th2-cell1{
              width: 6vw;
              border: 1px solid #185378;
              background: #01a4bf;
              span{
                display: block;
                width: 2vw;
                height: 4vh;
                line-height: 4vh;
                color: #fff;
                border-right: 1px solid #185378;
                text-align: center;
                font-size: 1.5vw;
              }
              span:last-child{
                border-right: none;
              }
            }
            .th2-cell2{
              color: #00e4ff;
              background: url('../../../assets/images/jltzn2.png') no-repeat 0 0;
              background-size: 100% 100%;
              width: 8vw;
              height: 10vh; 
              text-align: center;
              font-size: 1.5vw;
              span{
                display: inline-block;
                padding-top: 1vh;
              }
            }
          }
        }
        }
    }
  }
}
</style>